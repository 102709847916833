exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archives-js": () => import("./../../../src/pages/archives.js" /* webpackChunkName: "component---src-pages-archives-js" */),
  "component---src-pages-calcruin-js": () => import("./../../../src/pages/calcruin.js" /* webpackChunkName: "component---src-pages-calcruin-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-risk-of-ruin-js": () => import("./../../../src/pages/en/risk-of-ruin.js" /* webpackChunkName: "component---src-pages-en-risk-of-ruin-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-learn-to-invest-js": () => import("./../../../src/pages/learn-to-invest.js" /* webpackChunkName: "component---src-pages-learn-to-invest-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-summary-js": () => import("./../../../src/pages/summary.js" /* webpackChunkName: "component---src-pages-summary-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-lp-template-js-content-file-path-src-lp-2021-08-develop-md": () => import("./../../../src/templates/lp-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/lp/2021-08-develop.md" /* webpackChunkName: "component---src-templates-lp-template-js-content-file-path-src-lp-2021-08-develop-md" */),
  "component---src-templates-lp-template-js-content-file-path-src-lp-learn-by-email-md": () => import("./../../../src/templates/lp-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/lp/learn-by-email.md" /* webpackChunkName: "component---src-templates-lp-template-js-content-file-path-src-lp-learn-by-email-md" */),
  "component---src-templates-lp-template-js-content-file-path-src-lp-portfolio-md": () => import("./../../../src/templates/lp-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/lp/portfolio.md" /* webpackChunkName: "component---src-templates-lp-template-js-content-file-path-src-lp-portfolio-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-compare-6-strategies-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/compare-6-strategies.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-compare-6-strategies-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-ema-cycle-upper-cycle-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/ema-cycle-upper-cycle.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-ema-cycle-upper-cycle-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-how-to-backtest-trading-strategies-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/how-to-backtest-trading-strategies.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-how-to-backtest-trading-strategies-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-periodic-investment-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/periodic-investment.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-periodic-investment-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-sizing-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/sizing.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-sizing-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-stop-order-atr-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/stop-order-atr.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-stop-order-atr-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-testing-diversified-investment-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/testing-diversified-investment.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-testing-diversified-investment-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-atr-chanel-brakeout-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/tradingview-strategy-atr-chanel-brakeout.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-atr-chanel-brakeout-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-bollinger-brakeout-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/tradingview-strategy-bollinger-brakeout.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-bollinger-brakeout-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-donchian-trend-system-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/tradingview-strategy-donchian-trend-system.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-donchian-trend-system-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-double-ema-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/tradingview-strategy-double-ema.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-double-ema-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-time-exit-donchian-trend-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/tradingview-strategy-time-exit-donchian-trend.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-time-exit-donchian-trend-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-triple-ema-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/backtesting/tradingview-strategy-triple-ema.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-backtesting-tradingview-strategy-triple-ema-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-bot-fx-bot-aws-cloud-9-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/bot/fx-bot-aws-cloud9.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-bot-fx-bot-aws-cloud-9-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-bot-fx-bot-python-cloud-9-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/bot/fx-bot-python-cloud9.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-bot-fx-bot-python-cloud-9-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-bot-fx-bot-python-google-sheet-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/bot/fx-bot-python-google-sheet.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-bot-fx-bot-python-google-sheet-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-bot-fx-bot-python-oanda-api-v-20-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/bot/fx-bot-python-oanda-api-v20.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-bot-fx-bot-python-oanda-api-v-20-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-how-to-win-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/learn-to-invest/how-to-win.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-how-to-win-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-investing-for-retirement-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/learn-to-invest/investing-for-retirement.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-investing-for-retirement-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-investing-three-quizzes-answer-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/learn-to-invest/investing-three-quizzes-answer.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-investing-three-quizzes-answer-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-investing-three-quizzes-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/learn-to-invest/investing-three-quizzes.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-investing-three-quizzes-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-lets-try-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/learn-to-invest/lets-try.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-lets-try-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-what-is-your-goal-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/learn-to-invest/what-is-your-goal.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-learn-to-invest-what-is-your-goal-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-asset-and-disparity-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/mesure-the-risk/asset-and-disparity.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-asset-and-disparity-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-hasan-kakuritsu-4-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/mesure-the-risk/hasan-kakuritsu4.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-hasan-kakuritsu-4-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-how-many-times-losing-streak-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/mesure-the-risk/how-many-times-losing-streak.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-how-many-times-losing-streak-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-law-of-large-numbers-2-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/mesure-the-risk/law-of-large-numbers-2.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-law-of-large-numbers-2-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-law-of-large-numbers-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/mesure-the-risk/law-of-large-numbers.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-law-of-large-numbers-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-max-risk-matrix-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/mesure-the-risk/max-risk-matrix.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-max-risk-matrix-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-multiple-strategies-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/mesure-the-risk/multiple-strategies.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-multiple-strategies-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-risk-of-ruin-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/mesure-the-risk/risk-of-ruin.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-risk-of-ruin-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-trading-edge-and-law-of-large-numbers-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/mesure-the-risk/trading-edge-and-law-of-large-numbers.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-trading-edge-and-law-of-large-numbers-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-trading-win-pct-and-risk-reward-ratio-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/mesure-the-risk/trading-win-pct-and-risk-reward-ratio.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-mesure-the-risk-trading-win-pct-and-risk-reward-ratio-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-my-rules-2016-10-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/my-rules/2016-10.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-my-rules-2016-10-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-my-rules-2018-09-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/my-rules/2018-09.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-my-rules-2018-09-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-my-rules-2021-04-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/my-rules/2021-04.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-my-rules-2021-04-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-calc-techs-js-gas-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/calc-techs-js-gas.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-calc-techs-js-gas-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-correl-js-gas-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/correl-js-gas.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-correl-js-gas-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-get-prices-on-google-spreadsheet-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/get-prices-on-google-spreadsheet.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-get-prices-on-google-spreadsheet-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-calc-ruin-probability-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/google-colab-calc-ruin-probability.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-calc-ruin-probability-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-calculate-trading-edge-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/google-colab-calculate-trading-edge.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-calculate-trading-edge-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-correlation-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/google-colab-correlation.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-correlation-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-edinet-screening-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/google-colab-edinet-screening.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-edinet-screening-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-install-ta-lib-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/google-colab-install-ta-lib.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-install-ta-lib-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-ku-chart-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/google-colab-ku-chart.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-ku-chart-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-matplotlib-make-graph-images-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/google-colab-matplotlib-make-graph-images.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-matplotlib-make-graph-images-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-plotly-animation-graph-surface-contour-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/google-colab-plotly-animation-graph-surface-contour.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-plotly-animation-graph-surface-contour-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-sample-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/google-colab-sample.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-google-colab-sample-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-mt-4-indicator-mql-4-sample-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/mt4-indicator-mql4-sample.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-mt-4-indicator-mql-4-sample-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-python-sample-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/python-sample.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-python-sample-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-spreadsheet-auto-get-csv-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/spreadsheet-auto-get-csv.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-spreadsheet-auto-get-csv-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-tech-auto-get-and-calc-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/tech-auto-get-and-calc.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-tech-auto-get-and-calc-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-tradingview-indicator-pine-script-sample-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/tradingview-indicator-pine-script-sample.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-tradingview-indicator-pine-script-sample-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-tradingview-pinescript-v-4-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/tradingview-pinescript-v4.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-tradingview-pinescript-v-4-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-tradingview-strategy-pine-script-sample-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/programming/tradingview-strategy-pine-script-sample.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-tradingview-strategy-pine-script-sample-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-study-2-ch-stock-trader-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/study/2ch-stock-trader.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-study-2-ch-stock-trader-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-study-all-of-active-investment-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/study/all-of-active-investment.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-study-all-of-active-investment-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-study-books-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/study/books.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-study-books-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-study-correlation-coefficient-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/study/correlation-coefficient.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-study-correlation-coefficient-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-study-principles-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/study/principles.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-study-principles-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-study-trading-session-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/study/trading-session.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-study-trading-session-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-study-way-of-the-tartle-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/study/way-of-the-tartle.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-study-way-of-the-tartle-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-1-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/take-advantage/compound-interest-1.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-1-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-2-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/take-advantage/compound-interest-2.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-2-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-3-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/take-advantage/compound-interest-3.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-3-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-4-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/take-advantage/compound-interest-4.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-4-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-5-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/take-advantage/compound-interest-5.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-5-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-6-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/take-advantage/compound-interest-6.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-take-advantage-compound-interest-6-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-technical-analysis-atr-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/technical-analysis/atr.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-technical-analysis-atr-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-trade-tools-how-to-manage-investment-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/trade-tools/how-to-manage-investment.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-trade-tools-how-to-manage-investment-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-trade-tools-saxobank-1-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/trade-tools/saxobank-1.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-trade-tools-saxobank-1-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-trade-tools-saxobank-2-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/trade-tools/saxobank-2.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-trade-tools-saxobank-2-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-trade-tools-start-google-colaboratory-md": () => import("./../../../src/templates/post-template.js?__contentFilePath=/home/yuya/gatsbyjs/InvestmentTechHack/src/posts/trade-tools/start-google-colaboratory.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-trade-tools-start-google-colaboratory-md" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tags-template.js" /* webpackChunkName: "component---src-templates-tags-template-js" */)
}

